import React from 'react';
import { Accent, ButtonType } from '../../../globals';
import Button from '../Button/Button';
import './AnnouncementHeader.scss';

interface AnnouncementHeaderProps {
    className?: string;
    children?: React.ReactNode;
    post: Frontmatter
}

//hardcoded it to avoid copy pasting everywhere
const AnnouncementHeader: React.FC<AnnouncementHeaderProps> = (
    props: AnnouncementHeaderProps
) => {
    return (
        <div className={props.className || 'announcement-header'}>
            <div className="announcement-header__content">
                <p>{props.post.title}</p>
                <Button
                    accent={Accent.primary}
                    type={ButtonType.linkExternal}
                    onClickLink={props.post.suburl}
                    className="announcement-header__content__button"
                >
                    Read More
                </Button>
            </div>
        </div>
    );
};

export default AnnouncementHeader;
